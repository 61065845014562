import { createRouter, createWebHistory } from 'vue-router'
import RegisterView from '../views/RegisterView.vue'

const routes = [
  {
    path: "/",
    name: "register2",
    component: RegisterView,
    meta: {
      title: "Kuepix | Sign up",
    },
  },

  {
    path: "/sign-up",
    name: "register",
    component: RegisterView,
    meta: {
      title: "Kuepix | Sign up",
    },
  },

  {
    path: "/sign-in",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      title: "Kuepix | Sign in",
    },
  },

  {
    path: "/verify-email",
    name: "verify",
    component: () => import("../views/VerifyView.vue"),
    meta: {
      title: "Kuepix | Verify email",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
