<template>
    
    <div class="row hp-authentication-page" style="height: 100vh;">
        <div class="hp-bg-black-20 hp-bg-color-dark-90 col-lg-5 col-12 dd-none" style="padding: 0; margin: 0; ">
            <img src="@/assets/img/sideImg.png"  alt="" style="width: 100%;">
        </div>

        <div class="col-12 col-lg-7 py-sm-64 py-lg-0" style="margin-top: -50px; ">
            <div class="row align-items-center justify-content-center h-100 mx-4 mx-sm-n32">
                <div class="col-12 col-md-9 col-xl-7 col-xxxl-5 px-8 px-sm-0 pt-24 pb-48">
                    <!-- <span class="d-block hp-p1-body hp-text-color-dark-0 hp-text-color-black-100 fw-medium mb-6"> Register</span> -->
                    <h1 class="mb-0 mb-sm-24" style="font-size: 36px; line-height: 52px; text-align: center; width: 100%;">Unlock Your Business and Personal potentials</h1>
                    <!-- <p class="mt-sm-8 mt-sm-0 text-black-60">Please sign up to your personal account if you want to use all our premium products.</p> -->

                    <form class="mt-16 mt-sm-32 mb-8"  @submit.prevent="signUp">
                        

                        <div class="mb-16" style="padding-top: 30px;">
                            <label for="registerEmail" class="form-label">Email </label>
                            <input type="email" class="form-control" required v-model="email" style="padding-top: 10px; padding-bottom: 10px;">
                        </div>

                       <div style="display: flex; width: 100%; justify-content: center; align-items: center; margin-top: 70px;">
                            <button class="btn btn-primary " style="width: 220px; height: 50px; color: #ffffff;">
                                Sign Up
                            </button>
                       </div>
                    </form>

                    <div class="col-12 hp-form-info text-center" style="margin-top: 20px;">
                        <router-link :to="{name: 'login'}" class="hp-caption" style="color: #9747ff; font-weight: 700;" >I have an account already</router-link>
                    </div>

                    <div style="display: flex; width: 100%; justify-content: center; align-items: center; margin-top: 100px;">
                            <a href="https://www.kuepix.com" target="_blank">

                                <button class="btn btn-primary " style="width: 205px; height: 66px; color: #ffffff;">
                                    Back to website
                                </button>

                            </a>
                    </div>

                   
                </div>
            </div>
        </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">
            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-6">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img src="@/assets/img/success.png" alt="Upgrade Account" class="hp-block mx-auto" style=" width: 200px; padding-top: 10px;">

                                <h5 class="mt-24 mb-16 me-4 ">Success!</h5>
                                <p class="hp-p1-body mb-0" >{{successText}}xxxxx</p>

                                <button class="btn btn-sm btn-primary" style="color: #ffffff; font-weight: 500; border-style: none; margin-top: 25px;" @click="closeS">Okay</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-6">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img src="@/assets/img/error.png" alt="Upgrade Account" class="hp-block mx-auto" style=" width: 150px; padding-top: 30px;">

                                <h5 class="mt-24 mb-16 me-4 text-danger" >Error!</h5>
                                <p class="hp-p1-body mb-0" >{{errorText}}</p>

                                <button class="btn btn-sm btn-primary" style="color: #ffffff; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeE">Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">
            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-6">
                    <div class="card-body">
                        <div class="row">

                            <div class="col-12 text-center">
                                <img src="@/assets/img/loading.png"  class="hp-block mx-auto" style="width: 150px; padding-top: 30px;" >
                            </div>

                            <div class="col-12 text-center">

                                <img src="@/assets/img/spinner.gif" alt="" style="width: 60px; margin-top: 10px;">

                                <p class="hp-p1-body mb-0" style="margin-top: 10px;">{{loadingText}}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>


<script>

    import { doc, setDoc, collection, query, where, getDocs, serverTimestamp, addDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {
        data(){
            return{
                loadingText: "",
                errorText: "",
                successText: "",

                email: "",
                agentId: "",
                agentName: "",
                agentEmail: "",
            }
        },

        mounted(){

            this.getAgentId();

        },

        methods:{

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            getAgentId(){

                let urlString = String(window.location.href);
                let url = new URL(urlString);
                let ref = url.searchParams.get("agentId");
                if(ref == null){
                    return;
                }

                this.agentId = String(ref);

            },

            async signUp(){

                //start loading
                this.loadingText= "Creating account...";
                this.$refs.loadingModal.classList.add("bg-active");

                //get agent name
                if(this.agentId != ""){

                    const q = query(collection(db, "Agents"), where("agentId", "==", this.agentId) );
                    const querySnapshot = await getDocs(q);

                    if(querySnapshot.docs.length > 0){

                        querySnapshot.forEach((doc) => {

                            this.agentName = doc.data().firstName +" "+doc.data().lastName;
                            this.agentEmail = doc.data().email;

                        })

                    }

                }

                //get verify pin
                let verifyPin = String(Math.floor(Math.random()*900000) + 100000);

                //get pin expiry
                let pinExpiry = (new Date().getTime() / (1000 * 60)) + 15;

                //check if email exists
                const usersRef = collection(db, "Users");
                const q = query(collection(db, "Users"), where("email", "==", this.email.toLowerCase()));

                const querySnapshot = await getDocs(q);
                if(querySnapshot.docs.length > 0){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "This email already exists";
                    console.log("ERROR");
                    return; 
                }

                //sign up
                const userRef = await addDoc(collection(db, "Users"), {
                    email: this.email.toLowerCase(),
                    verified: "no",
                    agentId: this.agentId,
                    agentName: this.agentName,
                    agentEmail: this.agentEmail,
                    dateJoined: serverTimestamp(),
                    notiCount: 0,
                    firstName: "",
                });

                sessionStorage.setItem("id", String(userRef.id));
                sessionStorage.setItem('email', String(this.email.toLowerCase()));
                sessionStorage.setItem('verifyPin', verifyPin);
                sessionStorage.setItem('pinExpiry', pinExpiry);
                sessionStorage.setItem('agentId', this.agentId);

                //send verify mail
                axios.post('https://api.kuepix.com/v1/verify-mail', {
                    toEmail: this.email,
                    pin: verifyPin
                })
                .then(function (response) {
                    console.log(response);
                    
                })

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$router.push({name: 'verify'});
                

            },

        }
    }

</script>