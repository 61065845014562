<template>
  <RegisterBody/>
</template>

<script>
// @ is an alias to /src
import RegisterBody from '@/components/RegisterBody.vue'

export default {
  name: 'RegisterView',
  components: {
    RegisterBody
  }
}
</script>
